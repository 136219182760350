import Icon from '@ant-design/icons';

import { IconProps } from '.';

const BiospecimenIcon = (props: IconProps) => (
  <Icon {...props}>
    <svg
      viewBox="0 0 18 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className={props.svgClass}
    >
      <path
        d="M8.99981 9.0625C8.99981 9.31114 9.09858 9.5496 9.27439 9.72541C9.45021 9.90123 9.68867 10 9.93731 10C10.1859 10 10.4244 9.90123 10.6002 9.72541C10.776 9.5496 10.8748 9.31114 10.8748 9.0625C10.8748 8.81386 10.776 8.5754 10.6002 8.39959C10.4244 8.22377 10.1859 8.125 9.93731 8.125C9.68867 8.125 9.45021 8.22377 9.27439 8.39959C9.09858 8.5754 8.99981 8.81386 8.99981 9.0625ZM17.6014 17.3336L13.3193 6.25V2.17188H14.9998V0.578125H2.99981V2.17188H4.68028V6.25L0.398244 17.3336C0.332619 17.507 0.297462 17.6898 0.297462 17.875C0.297462 18.7023 0.970119 19.375 1.79746 19.375H16.2021C16.3873 19.375 16.5701 19.3398 16.7436 19.2742C17.517 18.9766 17.9014 18.107 17.6014 17.3336V17.3336ZM6.27403 6.54766V2.21875H11.7256V6.54766L13.8561 12.0625C13.3709 11.9383 12.8693 11.875 12.3584 11.875C10.924 11.875 9.56465 12.3789 8.48418 13.2812C7.68674 13.9474 6.68029 14.3117 5.64121 14.3102C4.87481 14.3102 4.13887 14.1156 3.48965 13.7547L6.27403 6.54766V6.54766ZM1.9334 17.7812L2.91074 15.2547C3.74746 15.6789 4.67793 15.9062 5.64356 15.9062C7.07793 15.9062 8.43731 15.4023 9.51777 14.5C10.3123 13.8391 11.3061 13.4711 12.3607 13.4711C13.1811 13.4711 13.9639 13.6938 14.6482 14.1063L16.0662 17.7812H1.9334Z"
        fill="lurrentColor"
      />
    </svg>
  </Icon>
);

BiospecimenIcon.defaultProps = {
  viewBox: '0 0 1024 1024',
};
export default BiospecimenIcon;
