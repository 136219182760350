import * as React from 'react';
import { IconProps } from 'components/Icons';

const KidsFirstLoginIcon = ({ width = 56, height = 59, className = '' }: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 56 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="1.5" width="56" height="56" rx="28" fill="white" />
    <path
      d="M44.7955 37.209C47.7407 32.0063 47.0438 25.9163 44.5387 20.3306C48.9102 23.5267 50.2091 33.2596 48.2802 38.7943C46.554 43.7395 41.6841 42.7074 44.7955 37.209Z"
      fill="#DFDFDF"
    />
    <path
      d="M14.3288 45.9204C10.6132 43.4904 7.99378 39.79 6.72937 35.5874C12.7601 36.4705 15.1983 40.2475 14.3288 45.9204Z"
      fill="#DFDFDF"
    />
    <path
      d="M18.4866 14.4766C13.8475 16.347 11.1504 20.9007 10.2507 25.6842C9.49115 29.7335 6.87387 28.8675 6 29.3654C6.70772 23.6435 11.2799 15.0277 18.4866 14.4766Z"
      fill="#DFDFDF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.8634 45.6479C32.8418 48.4312 31.2149 52.1018 32.9971 53.5637C34.3694 54.6893 35.7374 51.5337 37.1032 49.2185C38.6633 46.5714 39.4616 47.1353 42.724 46.8417C50.2997 46.1586 48.744 44.1499 44.9594 43.5264C43.5461 43.2924 41.4834 42.9902 40.5275 40.9432C39.6213 39.0004 40.5879 37.1662 42.0983 35.0532C44.146 32.189 44.7307 30.5463 40.2492 33.0594C35.6338 35.6469 35.3836 36.3129 30.5395 33.8084C24.0341 30.4463 26.0127 33.8658 28.9795 36.7406C31.3465 39.0323 32.8914 42.322 32.8634 45.6479Z"
      fill="#0D99B7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5965 23.1286C16.7369 22.2051 20.0922 22.6349 20.5841 19.958C22.4484 21.2879 21.4839 25.7374 17.2915 27.712C13.9449 29.2909 11.319 24.5436 14.5965 23.1286Z"
      fill="#CC3399"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.8824 43.4626C23.9975 37.2406 22.7461 32.9444 25.5813 26.3842C28.9775 18.5237 26.9795 19.76 23.0481 24.784C19.5721 29.227 15.2696 29.5866 8.7987 30.974C4.92348 31.8039 4.96447 33.3594 10.212 33.5785C16.2125 33.8275 17.4165 37.8854 17.2418 42.9796C17.1835 44.6968 16.9116 46.4246 16.3398 47.9886C14.4022 53.2828 16.8189 52.821 18.7522 50.1463C19.7145 48.8142 20.5754 47.3141 21.0069 45.5926C21.883 42.0944 22.8518 43.0093 24.4139 45.3351C29.8211 53.3764 28.9796 47.9865 26.8824 43.4626Z"
      fill="#CC3399"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.0317 33.3319C31.9033 32.1083 32.1039 29.4591 33.3662 28.3249C34.9737 26.8822 36.3978 27.044 37.2781 27.9164C40.3463 30.9486 35.5023 36.0087 33.0317 33.3319Z"
      fill="#0D99B7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.2624 17.909C35.9243 20.9306 27.8632 19.5921 23.278 15.9109C21.0772 14.1447 21.4893 14.1001 23.8455 13.5234C25.8802 13.0255 27.0821 12.6361 29.4771 14.4192C30.9012 15.4789 32.3533 16.2577 33.6846 16.579C36.8543 17.343 39.2493 17.277 41.4264 16.4875C45.5994 14.9725 45.7246 15.3832 41.2624 17.909Z"
      fill="#90278E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.2651 9.98481C35.4411 12.4319 38.1058 14.5725 40.9453 14.6832C43.0189 14.764 44.3221 14.2193 41.0273 15.4152C39.0681 16.1259 36.8824 16.177 33.9566 15.4726C32.8583 15.2088 31.65 14.5789 30.4438 13.7129C27.5223 11.619 30.241 10.8849 32.3556 9.14429C34.3795 7.4739 33.1755 7.71648 34.2651 9.98481Z"
      fill="#00ADEE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.6485 4.7393C26.9572 5.69047 28.9466 6.0671 31.1453 6.21606C33.1908 6.35437 33.5965 6.1352 32.017 8.02051C31.2856 8.89294 30.3901 9.63132 29.389 10.2484C26.981 11.7337 24.8513 8.74824 23.6236 6.14371C22.4628 3.68387 22.3441 3.79026 24.6485 4.7393Z"
      fill="#BE1E2D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0677 10.5571C22.2231 9.63361 22.3439 8.60371 22.3828 7.54615C22.4432 5.85235 22.1778 5.82895 23.1077 7.32272C23.6105 8.12919 24.2233 9.00801 24.7649 9.72936C25.6776 10.9401 26.3637 11.7402 24.8771 12.1488C24.3808 12.285 23.8802 12.4062 23.3775 12.5105C20.95 13.0106 21.7117 12.6616 22.0677 10.5571Z"
      fill="#F6921E"
    />
  </svg>
);

export default KidsFirstLoginIcon;
